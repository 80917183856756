import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Row, Col, Container, Breadcrumb } from 'react-bootstrap';
const BreadcrumbsBottom = (props) => {
  const data = useStaticQuery(graphql`
    query{

      allStrapiAllMenus {
        edges {
          node {
          	Label
            URL
            Alias
            Sub_Parent {
              Label
              Alias
              URL
            }
            Main_Parent {
           	  Label
              Alias
              URL
            }
          }
        }
      }

	  allStrapiArticles {
	    edges {
	      node {
	        Alias
	        Pagename
	      }
	    }
	  }

    }
  `);
  const PageURL = data.allStrapiAllMenus.edges.filter(item => item.node?.Alias === props?.alias).pop()?.node;

  var parent = ""
  var url = ""

  if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent == null)) {

    const Parent = data.allStrapiArticles.edges.filter(item => item.node?.Alias === PageURL.Main_Parent.Alias).pop()?.node;

    parent = Parent?.Pagename
    url = PageURL?.Main_Parent?.URL

  }

  else if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent != null)) {

    const Parent = data.allStrapiArticles.edges.filter(item => item.node?.Alias === PageURL.Main_Parent.Alias).pop()?.node;

    const Sub_Parent = data.allStrapiArticles.edges.filter(item => item.node?.Alias === PageURL.Sub_Parent.Alias).pop()?.node;

    parent = Parent?.Pagename
    var sub_parent = Sub_Parent?.Pagename
    url = PageURL?.Main_Parent?.URL
    var sub_url = PageURL?.Sub_Parent?.URL
    
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''    
    if (pageurl == 'https://www.bentleyhurst.co.uk/property-services/rent/zero-deposit/') {  
      sub_parent = 'Rent';
      sub_url = 'rent';
    }
  }
 
  return (
    <div className="breadcrumb-block text-center" >
      <Container>
        <Row>
          <Col lg={12}>
            <Breadcrumb>
              {props.Page !== 'Home' &&

                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              }
              {PageURL?.Main_Parent !== null &&
                <Breadcrumb.Item href={`/${url}`}>{parent}</Breadcrumb.Item>
              }
              {props.type === "details-page" &&
                <Breadcrumb.Item href={`/${url}/${PageURL?.URL}`}>{PageURL?.Label}</Breadcrumb.Item>


              }

              {PageURL?.Sub_Parent !== null &&
                <Breadcrumb.Item href={`/${url}/${sub_url}`}>{sub_parent}</Breadcrumb.Item>
              }
              <Breadcrumb.Item active>{props.Page}</Breadcrumb.Item>

            </Breadcrumb>

          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BreadcrumbsBottom;

