import React,{ useState } from "react";
import { Container, Row, Col} from 'react-bootstrap';
import  './ContentBlockText.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"

const ContentBlockText = ( props ) => {


  return(

      <div className="static-content">
         <Container>
             <Row>
             <Col xs="12">
             <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className={props.fullwidth? "static-content-block fullwidth" : "static-content-block"}>
              
<div className="rich-text-editer-block">
<ReactMarkdown source={props?.data} allowDangerousHtml />
</div>

                </div> 
                </ScrollAnimation>
             </Col>
             </Row>
             
         </Container>
      </div>
         
  
)
  }



export default ContentBlockText